import { GetServerSideProps } from "next";
import * as React from "react";
import { ssrMe } from "@graphql/generated/page";
import LoginForm from "@components/login/login-form";

interface ILoginProps {}

export const getServerSideProps: GetServerSideProps = async (ctx) => {
  const { props } = await ssrMe.getServerPage({}, ctx);

  if (props.data.me) {
    return {
      redirect: {
        destination: "/",
        permanent: true,
      },
    };
  }

  return { props };
};

const Login: React.FunctionComponent<ILoginProps> = (props) => {
  return <LoginForm />;
};

export default Login;
