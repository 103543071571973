import * as React from "react";
import {
  LoginMutationVariables,
  MeDocument,
  MeQuery,
  useLoginMutation,
} from "@graphql/generated/graphql";
import { useFeedback } from "@lib/feedbackContext";
import { useRouter } from "next/router";
import { useForm } from "react-hook-form";

interface ILoginFormProps {}

const LoginForm: React.FunctionComponent<ILoginFormProps> = () => {
  const router = useRouter();
  const [login] = useLoginMutation();
  const { handleSubmit, register } = useForm<LoginMutationVariables>();
  const { enqueueFeedback } = useFeedback();

  const onSubmit = handleSubmit(async (variables: LoginMutationVariables) => {
    const { data } = await login({
      variables,
      update: (client, { data }) => {
        if (data?.login?.me) {
          client.writeQuery<MeQuery>({
            query: MeDocument,
            data: {
              me: data.login.me,
            },
          });
        }
      },
    });

    if (data?.login) {
      const {
        login: { me, errors },
      } = data;

      if (me) {
        router.push("/");
      } else if (errors) {
        enqueueFeedback(errors[0]["detail"], true);
      }
    }
  });

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        {/* TODO: add logo */}
        {/* <img className="mx-auto h-12 w-auto" src="/logo.svg" alt="Logo" /> */}
        <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
          Sign in to your account
        </h2>
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <form className="space-y-6" onSubmit={onSubmit}>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email address
              </label>
              <div className="mt-1">
                <input
                  id="email"
                  type="email"
                  autoComplete="email"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register("email")}
                />
              </div>
            </div>

            <div>
              <label
                htmlFor="password"
                className="block text-sm font-medium text-gray-700"
              >
                Password
              </label>
              <div className="mt-1">
                <input
                  id="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  {...register("password")}
                />
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Sign in
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default LoginForm;
